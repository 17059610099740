<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card>
        <b-row
          align-h="between"
          class="mt-1"
        >
          <b-col>
            <b-button
              v-b-modal.filtroRelatorio
              variant="warning"
              class="mb-2"
            >
              Filtro
            </b-button>
          </b-col>
          <b-col cols="1">
            <b-button
              variant="primary"
              class="mb-2"
              @click="exportar"
            >
              Exportar
            </b-button>
          </b-col>
        </b-row>
        <b-table
          small
          striped
          responsive
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(cpf_cnpj)="data">
            {{ data.item.cpf_cnpj ? cpfCNPJ(data.item.cpf_cnpj) : '' }}
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
        />
      </b-card>
    </b-overlay>
    <b-modal
      id="filtroRelatorio"
      ref="filtroRelatorio"
      title="Filtro"
      ok-title="Filtrar"
      cancel-title="Cancelar"
      cancel-variant="outline-danger"
      @ok="getRelatorio"
    >
      <b-row
        align-h="around"
        class="mb-2 mt-2"
      >
        <b-col cols="start">
          <b-row align-h="center">
            <h4>Inicio</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dataInicial"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>
        <b-col cols="end">
          <b-row align-h="center">
            <h4>Fim</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dataFinal"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>

      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BPagination,
  BOverlay,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { saveAs } from 'file-saver'
import axios from '../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
  },
  data() {
    return {
      fields: [
        { key: 'credor_id', label: 'Credor ID', class: 'text-center' },
        { key: 'cliente_id', label: 'Cliente ID', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF/CNPJ', class: 'text-center' },
      ],
      items: [],
      currentPage: 1,
      totalRows: 1,
      load: false,
      dataInicial: '',
      dataFinal: '',
      perPage: 20,
    }
  },
  created() {
    const dataFinal = new Date().toISOString().split('T')[0]
    const dataInicial = new Date()
    dataInicial.setDate(dataInicial.getDate() - 7)
    const dataInicialFormatada = dataInicial.toISOString().split('T')[0]

    this.dataInicial = dataInicialFormatada
    this.dataFinal = dataFinal
    this.getRelatorio()
  },
  methods: {
    getRelatorio() {
      this.load = true
      this.items = []
      const body = {
        data_inicial: `${this.dataInicial} 00:00:00`,
        data_final: `${this.dataFinal} 23:59:59`,
      }
      axios
        .post('api/v1/relatorios/backoffice/acoes/nao_acionados', body)
        .then(res => {
          this.load = false
          this.items = res.data.dados
          this.totalRows = this.items.length
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'ERROR!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                comfirmButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    async exportar() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.items

      worksheet.columns = [
        { header: 'CREDOR ID', key: 'credor_id' },
        { header: 'CLIENTE ID', key: 'cliente_id' },
        { header: 'CPF/CNPJ', key: 'cpf_cnpj' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.credor_id,
          item.cliente_id,
          item.cpf_cnpj,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de Clientes Não acionados', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
  },
}
</script>
